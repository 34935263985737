<template>
  <div class="home">
    <div class="common_top"></div>
    <div v-if="info.banner" class="banner">
      <swiper :options="swiperOption">
        <swiper-slide v-for="item in info.banner" :key="item.id">
          <img :src="$commonJs.formatImgSrc(item.path)" />
          <div class="banner_content">
            <div class="max_width_1200">
              <div style="max-width: 50%; z-index: 11; position: relative">
                <h1 class="banner_content_p1">{{ item.title }}</h1>
                <h1 v-if="item.small_title" class="banner_content_p2">
                  {{ item.small_title }}
                </h1>
                <p class="banner_content_p3">
                  {{ item.desc }}
                </p>
                <button
                  @click="bannerClick(item)"
                  class="banner_content_btn common_btn_style"
                >
                  {{ item.btn_text }}
                </button>
                <!-- <div class="swiper_pagination" slot="pagination"></div> -->
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div style="z-index: 9; position: absolute; bottom: 133px; width: 100%">
        <div
          style="padding-left: 49px"
          class="swiper_pagination max_width_1200"
          slot="pagination"
        ></div>
      </div>
    </div>
    <div class="customers">
      <div class="customers_content max_width_1200">
        <LoopSwiper v-if="info.brand" :imageList="info.brand"></LoopSwiper>
      </div>
    </div>
    <!-- 多场景应用 -->
    <div v-if="info.scene" class="apply_block">
      <div class="apply_block_content max_width_1200">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <p class="apply_block_title">
            {{ info.scene.title }}
          </p>
          <p
            style="
              width: 2px;
              height: 21px;
              background: #222222;
              border-radius: 0px 0px 0px 0px;
              margin-left: 10px;
              margin-right: 16px;
            "
          ></p>
          <p class="apply_block_title">
            {{ info.scene.title_small }}
          </p>
        </div>
        <div class="apply_block_tabs">
          <TabSwiper
            @tabChange="tabChange1"
            v-if="info.scene.list"
            :list="info.scene.list"
            from="apply"
            :slidesPerView="
              info.scene.list.length > 5 ? 5 : info.scene.list.length
            "
          ></TabSwiper>
        </div>
        <div v-if="applyItem.id" class="apply_block_tabs_card">
          <div class="apply_block_tabs_card_top_outer">
            <div class="apply_block_tabs_card_top">
              <img src="../assets/img/dianzanicon.png" alt="" />
              <span>{{ applyItem.btn_text }}</span>
            </div>
          </div>
          <div class="apply_block_tabs_card_center">
            <p class="apply_block_tabs_card_center_title">
              {{ applyItem.desc_title }}
            </p>
            <div class="apply_block_tabs_card_center_ps">
              <div
                v-for="(item, index) in applyItem.title_list"
                :key="index"
                class="apply_block_tabs_card_center_ps_item"
              >
                <div
                  style=""
                  class="apply_block_tabs_card_center_ps_item_dot_outer"
                >
                  <span class="apply_block_tabs_card_center_ps_item_dot"></span>
                </div>
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
          <div v-if="applyItem.image_list" class="apply_block_tabs_card_bot">
            <div class="apply_block_tabs_card_bot_left">
              <img
                class=""
                :src="
                  $commonJs.formatImgSrc(
                    applyItem.image_list[applyMouseIndex].image
                  )
                "
                alt=""
              />
            </div>
            <div class="apply_block_tabs_card_bot_right">
              <template v-for="(item, index) in applyItem.image_list">
                <div
                  @mouseover="applyMouseover(item, index)"
                  @mouseout="applyMouseout(item, index)"
                  :key="index"
                  class="apply_block_tabs_card_bot_right_item"
                  :class="
                    applyMouseIndex == index
                      ? 'apply_block_tabs_card_bot_right_item_active'
                      : ''
                  "
                >
                  <img
                    class="item_icon"
                    :src="$commonJs.formatImgSrc(item.icon)"
                    alt=""
                  />
                  <img
                    class="item_activeicon"
                    :src="$commonJs.formatImgSrc(item.sel_icon)"
                    alt=""
                  />
                  <span>{{ item.title }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="block_btn_outer">
          <button @click="openForm" class="block_btn common_btn_style">
            {{ info.scene.btn_text }}
          </button>
        </div>
      </div>
    </div>
    <!-- 全流程 -->
    <div v-if="info.department" class="hui_block">
      <div class="max_width_1200">
        <p class="block_title">{{ info.department.title }}</p>
        <div class="apply_block_tabs hui_block_tabs">
          <TabSwiper
            @tabChange="tabChange2"
            v-if="info.department.contents"
            :list="info.department.contents"
            :slidesPerView="3"
          ></TabSwiper>
        </div>
        <div v-if="huiBlockItemImage" class="hui_block_img">
          <img :src="huiBlockItemImage" alt="" />
        </div>
        <div class="block_btn_outer">
          <button @click="openForm" class="block_btn common_btn_style">
            {{ info.department.btn_text }}
          </button>
        </div>
      </div>
    </div>
    <!-- 解决方案需求 -->
    <div v-if="info.programme" class="demand_block">
      <div class="max_width_1200">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <p class="block_title">
            {{ info.programme.title }}
          </p>
          <p class="block_title" style="margin-left: 1.5rem">
            {{ info.programme.subtitle }}
          </p>
        </div>
        <div v-if="info.programme.contents" class="demand_block_center">
          <template v-for="(item, index) in info.programme.contents">
            <div :key="index" class="demand_block_center_item">
              <img
                class="demand_block_center_item_icon rotate_img"
                :src="$commonJs.formatImgSrc(item.icon)"
                alt=""
              />
              <p class="demand_block_center_item_title">{{ item.title }}</p>
              <img
                class="demand_block_center_item_desc_top"
                src="../assets/img/diandiantu.png"
                alt=""
              />
              <p class="demand_block_center_item_desc">
                {{ item.desc }}
              </p>
            </div>
          </template>
        </div>
        <div class="block_btn_outer">
          <button @click="openForm" class="block_btn common_btn_style">
            {{ info.programme.btn_text }}
          </button>
        </div>
      </div>
    </div>
    <!-- 灰色块信任 -->
    <div v-if="info.trust" class="hui2_block">
      <div class="max_width_1200">
        <template>
          <p class="block_title">{{ info.trust.title }}</p>
          <div class="hui2_block_number">
            <div class="hui2_block_number_column">
              <span class="hui2_block_number_column_top">{{
                info.trust.contents.left_number
              }}</span>
              <span class="hui2_block_number_column_bot">{{
                info.trust.contents.left_title
              }}</span>
            </div>
            <div
              class="hui2_block_number_column hui2_block_number_column_right"
            >
              <span class="hui2_block_number_column_top">{{
                info.trust.contents.right_number
              }}</span>
              <span class="hui2_block_number_column_bot">{{
                info.trust.contents.right_title
              }}</span>
            </div>
          </div>
          <p class="hui2_block_title">合作集团</p>
          <div class="hui2_block_images">
            <img
              :src="$commonJs.formatImgSrc(info.trust.contents.image)"
              alt=""
            />
          </div>
          <p v-if="info.cooperation" class="hui2_block_title">
            {{ info.cooperation.title }}
          </p>
        </template>
      </div>
      <!-- 轮播的合作品牌 -->
      <div v-if="info.cooperation" class="hui2_block_brands">
        <BrandsSwiper
          v-if="info.cooperation.list"
          :loopSwiperList="info.cooperation.list"
        ></BrandsSwiper>
      </div>
      <div class="max_width_1200">
        <template>
          <div class="block_btn_outer hui2_block_btn">
            <button @click="toCustomers" class="block_btn common_btn_style">
              {{ info.cooperation.btn_text }}
            </button>
          </div>
        </template>
      </div>
    </div>
    <!-- 内容干货 -->
    <div v-if="info.news" class="dry_block">
      <div class="max_width_1200">
        <p class="block_title">{{ info.news.title }}</p>
        <div class="dry_block_center">
          <template v-for="(item, index) in info.news.list">
            <div
              @click="articleClick(item)"
              :key="index"
              class="dry_block_center_item"
              :style="index % 5 == 0 ? 'margin-left:0' : ''"
            >
              <div class="dry_block_center_img">
                <img
                  class=""
                  :src="$commonJs.formatImgSrc(item.image)"
                  alt=""
                />
              </div>
              <div class="dry_block_center_bot">
                <p>{{ item.title }}</p>
              </div>
            </div>
          </template>
        </div>
        <div class="block_btn_outer dry_block_btn">
          <button @click="openArticleList()" class="block_btn common_btn_style">
            {{ info.news.btn_text }}
          </button>
        </div>
      </div>
    </div>

    <FormComponents
      @handlerCancel="handlerCancel"
      :dialogVisible="dialogVisible"
    ></FormComponents>
  </div>
</template>

<script>
import { getHomeInfo } from "@/network/public";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import LoopSwiper from "@/components/loopSwiper.vue";
import TabSwiper from "@/components/tabSwiper.vue";
import RightFix from "@/components/rightFix.vue";
import BrandsSwiper from "@/components/brandsSwiper.vue";

import "swiper/css/swiper.css";
export default {
  name: "SecurityIndex",
  components: {
    Swiper,
    SwiperSlide,
    LoopSwiper,
    TabSwiper,
    RightFix,
    BrandsSwiper,
  },
  data() {
    return {
      huiBlockIndex: 0,
      huiBlockItemImage: "",
      applyMouseIndex: 0,
      applyItem: {},
      applyIndex: 0,
      info: {},
      dialogVisible: false,
      imageList: [],
      swiperOption: {
        // loop:true,
        observer: true, //开启动态检查器，监测swiper和slide
        observeParents: true, //监测Swiper 的祖/父元素
        observeSlideChildren: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
        },
        slideToClickedSlide: true,
        pagination: {
          el: ".swiper_pagination",
          clickable: true,
        },
      },
    };
  },

  mounted() {
    this.getInfo();
  },

  methods: {
    articleClick(item, hase) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          id: item.id,
        },
      });
    },
    handlerCancel() {
      this.dialogVisible = false;
    },
    openForm() {
      this.dialogVisible = true;
    },
    openArticleList() {
      this.$router.push({
        path: "/news",
      });
    },
    bannerClick(item) {
      if (item.url) {
        window.open(item.url, "_blank");
      }
    },
    toCustomers() {
      this.$router.push({
        path: "/customers",
      });
    },
    applyMouseover(item, index) {
      // console.log("applyMouseover", index);
      if (this.applyMouseIndex != index) {
        this.applyMouseIndex = index;
      }
    },
    applyMouseout(item, index) {
      // console.log("applyMouseout", index);
      // this.applyMouseIndex = 0;
    },
    getInfo() {
      getHomeInfo()
        .then((res) => {
          if (res.status == 200) {
            this.info = res.data;
            this.tabChange1(0);
            this.tabChange2(0);
            // for (let index = 0; index < 100; index++) {
            //   this.info.cooperation.list.push(this.info.cooperation.list[0]);
            // }
          }
        })
        .catch((e) => {});
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    tabChange1(e) {
      this.applyIndex = e;
      if (this.info.scene) {
        this.applyItem = this.info.scene.list[e];
      }
    },

    tabChange2(e) {
      console.log("tabChange2", e);
      this.huiBlockIndex = e;
      if (this.info.department) {
        this.huiBlockItemImage = this.$commonJs.formatImgSrc(
          this.info.department.contents[e].image
        );
      }
    },
  },
};
</script>

<style lang="scss" >
.banner {
  position: relative;

  .banner_content {
    position: absolute;
    top: 218px;
    display: flex;
    flex-direction: column;
    left: 39px;
    width: calc(100% - 39px);
    box-sizing: border-box;

    .banner_content_p1 {
      font-weight: 700;
      font-size: 46px;
      color: #222222;
      line-height: 46px;
    }
    .banner_content_p2 {
      font-weight: 700;
      font-size: 46px;
      color: #222222;
      line-height: 46px;
      margin-top: 25px;
    }
    .banner_content_p3 {
      font-weight: 290;
      font-size: 19px;
      color: #222222;
      line-height: 27px;
      margin-left: 5px;
      letter-spacing: 1px;
      margin-top: 22px;
    }

    .banner_content_btn {
      margin-top: calc(33px - 6px);
      background: #006c50;
      border-radius: 50px;
      border: 6px solid red;
      padding: 15px 49px 15px 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      line-height: 18px;
      background-clip: padding-box;
    }
  }
  .swiper-slide {
    img {
      width: 100%;
      height: 628px;
      vertical-align: middle;
      object-fit: cover;
      // height: 562px;
    }
  }
  .swiper_pagination {
    margin-top: 22px;
    // position: absolute;
    // bottom: 127px;
    // z-index: 9;
  }
  .swiper-pagination-bullet {
    margin-right: 7px;
    background: #006c50;
    width: 5.45px;
    height: 5.45px;
  }
  .swiper-pagination-bullet-active {
    background: rgba(0, 108, 80, 1);
  }
}

.customers {
  position: relative;
  top: -30px;
  z-index: 9;
  .customers_content {
    padding: 9px 0 13px 0;
    background: #ffffff;
    box-shadow: 4px 9px 17px 0px rgba(152, 152, 152, 0.3608);
    border-radius: 7px 7px 7px 7px;
  }
}
.apply_block {
  margin-top: 58px;
  padding-bottom: 58px;
  .apply_block_content {
    .apply_block_title {
      font-weight: 700;
      font-size: 30px;
      color: #222222;
      line-height: 30px;
      text-align: center;
    }

    .apply_block_tabs_card {
      margin-top: 30px;
      background: #ffffff;
      box-shadow: 0px 3px 16px 0px rgba(161, 161, 161, 0.1608);
      border-radius: 12px 12px 12px 12px;
      padding: 60px;
      .apply_block_tabs_card_top_outer {
        display: flex;
      }

      .apply_block_tabs_card_top {
        padding: 35px 13px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #006c50;
        display: flex;
        align-items: center;
        padding: 16px 34px;

        img {
          width: 18px;
          height: 18px;
        }
        span {
          // font-weight: 290;
          font-size: 16px;
          color: #222222;
          line-height: 18px;
          margin-left: 3px;
          white-space: nowrap;
          letter-spacing: 1px;
        }
      }
      .apply_block_tabs_card_center {
        &_title {
          font-weight: 400;
          font-size: 16px;
          color: #222222;
          line-height: 16px;
          margin-top: 34px;
        }
        .apply_block_tabs_card_center_ps {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          &_item {
            display: flex;
            // align-items: center;
            margin-top: 15px;
            &_dot_outer {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 20px;
            }
            &_dot {
              width: 6px;
              height: 6px;
              background: #006c50;
              display: block;
              border-radius: 50%;
            }
            p {
              margin-left: 10px;
              font-weight: 400;
              font-size: 14px;
              color: #8d9296;
              line-height: 20px;
              letter-spacing: 3.06%;
            }
          }
          .apply_block_tabs_card_center_ps_item:first-child {
            margin-top: 0px;
          }
        }
      }

      .apply_block_tabs_card_bot {
        display: flex;
        align-items: flex-start;
        margin-top: 30px;
        &_left {
          img {
            width: 706px;
            height: 228px;
            border-radius: 16px;
          }
        }
        &_right {
          margin-left: 12px;
          flex: 1;
          &_item {
            display: flex;
            align-items: center;
            padding: 14px 18px;
            margin-bottom: 10px;
            cursor: pointer;
            img {
              width: 18px;
              height: 18px;
              object-fit: contain;
            }
            span {
              font-weight: 400;
              font-size: 16px;
              color: #808080;
              line-height: 16px;
              margin-left: 13px;
            }
            .item_activeicon {
              display: none;
            }
            .item_icon {
              display: block;
            }
          }
          .apply_block_tabs_card_bot_right_item:hover {
            box-shadow: 0px 3px 16px 0px rgba(161, 161, 161, 0.2314);
            border-radius: 10px 10px 10px 10px;
            span {
              color: rgba(48, 48, 48, 1);
            }
            .item_icon {
              display: none;
            }
            .item_activeicon {
              display: block;
            }
          }
          .apply_block_tabs_card_bot_right_item_active {
            box-shadow: 0px 3px 16px 0px rgba(161, 161, 161, 0.2314);
            border-radius: 10px 10px 10px 10px;
            span {
              color: rgba(48, 48, 48, 1);
            }
            .item_icon {
              display: none;
            }
            .item_activeicon {
              display: block;
            }
          }
          &_item_active {
            box-shadow: 0px 3px 16px 0px rgba(161, 161, 161, 0.2314);
            border-radius: 10px 10px 10px 10px;
          }
        }
      }
    }
  }

  .apply_block_tabs {
    margin-top: 41px;
    overflow: hidden;
    box-sizing: border-box;
    .apply_block_tabs_left {
      cursor: pointer;
      background: #ffffff;
      z-index: 1;
      img {
        width: 38px;
        height: 38px;
        vertical-align: middle;
      }
    }
    .apply_block_tabs_right {
      cursor: pointer;
      img {
        width: 38px;
        height: 38px;
        vertical-align: middle;
      }
    }

    .apply_block_tabs_center {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      width: 100%;
      overflow-x: scroll;
      box-sizing: border-box;
    }

    .apply_block_tabs_item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 17px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      min-width: 20%;

      img {
        width: 30px;
        height: 26px;
      }
      span {
        font-weight: 700;
        font-size: 16px;
        color: #666666;
        line-height: 16px;
        margin-left: 8px;
        white-space: nowrap;
      }
    }
    .apply_block_tabs_item_active {
      border-bottom: 2px solid rgba(0, 109, 80, 1);
      span {
        font-weight: 700;
        font-size: 16px;
        color: rgba(34, 34, 34, 1);
        line-height: 16px;
        margin-left: 8px;
      }
    }
  }
}

.block_btn_outer {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  .block_btn {
    background: #006c50;
    border-radius: 50px;
    border-width: 6px;
    padding: 17px;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    line-height: 18px;
    min-width: 220px;
    white-space: nowrap;
  }
}

.hui_block {
  background: #f8f8f8;
  border-radius: 0px 0px 0px 0px;
  padding: 61px 0 63px 0;
  .hui_block_tabs {
    margin-top: 50px;
  }
  .hui_block_img {
    margin-top: 54px;
    img {
      width: 100%;
      // height: 630.68px;
      border-radius: 18px;
      object-fit: contain;
    }
  }
}

.block_title {
  font-weight: 400;
  font-size: 30px;
  color: #222222;
  line-height: 30px;
  text-align: center;
}

.demand_block {
  padding: 56px 0 61px 0;
  .demand_block_center {
    margin-top: 46px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 17px;
    .demand_block_center_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 56px 48px;
      background: #ffffff;
      box-shadow: 0px 3px 16px 0px rgba(152, 152, 152, 0.1608);
      border-radius: 16px 16px 16px 16px;
      min-height: 378.64px;
      .demand_block_center_item_icon {
        width: 78px;
        height: 78px;
        border-radius: 50%;
      }
      &_title {
        margin-top: 22px;
        font-weight: 700;
        font-size: 24px;
        color: #222222;
        line-height: 24px;
      }
      &_desc_top {
        width: 75px;
        height: 10.41px;
        margin-top: 16px;
        vertical-align: middle;
      }
      &_desc {
        margin-top: 33px;
        // font-weight: 290;
        font-size: 16px;
        color: #6c6c6c;
        line-height: 24px;
        max-width: 235.61px;
        letter-spacing: 1px;
        max-width: 238px;
        text-align: justify;
        position: relative;
        left: 0.28rem;
      }
    }
    .demand_block_center_item:hover {
      .rotate_img {
        transform: rotateY(360deg);
        transition: all 1s ease-in-out;
      }
    }
  }
}

.hui2_block {
  padding: 59px 0 60px 0;
  background: #f8f8f8;

  .hui2_block_number {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 42px;

    &_column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &_top {
        font-weight: 700;
        font-size: 62px;
        color: #006c50;
        line-height: 62px;
      }
      &_bot {
        // font-weight: 290;
        font-size: 20px;
        color: #6c6c6c;
        line-height: 20px;
        margin-top: 9px;
      }
    }
    .hui2_block_number_column_right {
      margin-left: 251px;
    }
  }

  &_title {
    font-weight: 400;
    font-size: 24px;
    color: #222222;
    line-height: 24px;
    text-align: center;
    margin-top: 61px;
  }
  .hui2_block_images {
    margin-top: 40px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  .hui2_block_brands {
    margin-top: 46px;
  }
  .hui2_block_btn {
  }
}

.dry_block {
  padding: 67px 0 85px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .dry_block_center {
    margin-top: 61px;
    // display: grid;
    // grid-template-columns: repeat(5, 1fr);
    // grid-gap: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .dry_block_center_item {
      border-radius: 18px 18px 18px 18px;
      background: #ffffff;
      box-shadow: 0px 2px 13px 0px rgba(161, 161, 161, 0.1608);
      border-radius: 18px 18px 18px 18px;
      width: calc(20% - 10px);
      margin-left: 11px;
      margin-top: 11px;
      transition: all 0.3s ease-out;
      cursor: pointer;

      &:hover {
        transform: translateY(-20px);
      }

      .dry_block_center_img {
        overflow: hidden;
        border-radius: 18px 18px 0 0;
        img {
          transition: all 0.3s ease;
          width: 100%;
          height: 210.54px;
          height: 160.54px;
          object-fit: cover;
          vertical-align: middle;
        }
      }
      .dry_block_center_img:hover {
        img {
          // transform: scale(1.1);
        }
      }
    }

    .dry_block_center_bot {
      padding: 36px;
      padding: 26px;

      padding-bottom: 47px;
      p {
        font-weight: 400;
        font-size: 18px;
        color: #222222;
        line-height: 28px;
      }
    }
  }

  .dry_block_btn {
    margin-top: 101px;
  }
}
</style>