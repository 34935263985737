let commonUrl = "https://quanming.craftsman-web.com.cn"
// commonUrl = "https://www.ekaquan.com"
/**
 * 服务器路径 
 */
export const BASE_URL = commonUrl;
/**
 * 富文本前缀 
 */
export const RICH_URL = commonUrl;


