  <template>
  <div class="navbar" v-if="footInfo.config">
    <div class="navbar_inner max_width_1200">
      <div class="navbar_left">
        <div
          @click="navClick(navList[0], 0)"
          v-if="footInfo.config"
          class="navbar_logo"
        >
          <img :src="$commonJs.formatImgSrc(footInfo.config.logo)" alt="" />
        </div>
        <div class="navbar_content">
          <div
            style="height: 100%"
            v-for="(item, index) in navList"
            :key="index"
          >
            <div
              @click="navClick(item, index)"
              class="navbar_content_item"
              :class="navIndex == item.id ? 'navbar_content_item_active' : ''"
            >
              <span class="navbar_content_item_span">{{ item.name }}</span>
              <img
                v-if="index != 0"
                class="navbar_content_item_icon"
                src="../assets/img/xiajiantouhei.png"
                alt=""
              />
              <div
                v-if="navIndex == item.id"
                class="navbar_content_item_border"
              ></div>
              <template v-if="showLevel2">
                <!-- 产品二级  :class="
                        childIndex == productIndex
                          ? 'product_card_left_item_active'
                          : ''
                      " -->
                <div
                  v-if="item.id == 2 && info.goods"
                  class="product_card nav_card"
                >
                  <div class="product_card_left">
                    <div
                      v-for="(childItem, childIndex) in info.goods.list"
                      :key="childIndex"
                      class="product_card_left_item"
                      @click.stop.prevent="
                        level2NavClick(item, index, childItem, childIndex)
                      "
                    >
                      <div class="product_card_left_item_inner">
                        <img
                          class="product_card_left_item_icon"
                          :src="childItem.contents.icon"
                          alt=""
                        />
                        <span class="product_card_left_item_span">{{
                          childItem.title
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="product_card_right">
                    <img
                      class="product_card_right_img"
                      :src="$commonJs.formatImgSrc(info.goods.image)"
                      alt=""
                    />
                    <!-- <div class="product_card_right_bot">
                      <span>点击了解更多</span>
                      <img
                        class="product_card_right_bot_icon"
                        src="@/assets/img/xiajiantouhei.png"
                        alt=""
                      />
                    </div> -->
                  </div>
                </div>
                <!-- 解决方案二级 :class="
                      childIndex == solutionIndex
                        ? 'solution_card_item_active'
                        : ''
                    "  -->
                <div
                  v-if="item.id == 3 && info.programme"
                  class="nav_card solution_card"
                  :style="
                    info.programme.list.length > 1
                      ? 'grid-column-gap: 94px;'
                      : ''
                  "
                >
                  <div
                    v-for="(childItem, childIndex) in info.programme.list"
                    :key="childIndex"
                    class="solution_card_item"
                    @click.stop.prevent="
                      level2NavClick(item, index, childItem, childIndex)
                    "
                  >
                    <div class="solution_card_item_icon">
                      <img
                        class=""
                        :src="$commonJs.formatImgSrc(childItem.icon)"
                        alt=""
                      />
                    </div>
                    <span class="solution_card_item_span">{{
                      childItem.sel_title
                    }}</span>
                    <div class="solution_card_item_logo">
                      <img
                        :src="$commonJs.formatImgSrc(childItem.tab_image)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <!-- 客户案例二级 -->
                <div
                  v-if="item.id == 4 && info.case"
                  class="nav_card case_card"
                >
                  <div class="case_card_column">
                    <p class="case_card_column_title">集团</p>
                    <div class="case_card_column_img_list">
                      <div
                        v-for="(childItem, childIndex) in currentGroupList"
                        :key="childIndex"
                        class="case_card_column_img"
                        @click.stop.prevent="
                          level2NavClick(item, index, childItem, childIndex)
                        "
                      >
                        <img
                          class=""
                          :src="$commonJs.formatImgSrc(childItem.image)"
                          alt=""
                        />
                      </div>
                    </div>
                    <div v-if="allGroupList" class="case_card_column_bot">
                      <div
                        @click.stop.prevent="getGroupList(2)"
                        class="case_card_column_bot_column"
                      >
                        <img
                          class="icon"
                          src="../assets/img/weixuanzhongzuo.png"
                          alt=""
                        />
                        <img
                          class="active_icon"
                          src="../assets/img/xuanzhongzuo.png"
                          alt=""
                        />
                      </div>
                      <div
                        @click.stop.prevent="getGroupList(1)"
                        class="case_card_column_bot_column case_card_column_bot_right"
                      >
                        <img
                          class="icon"
                          src="../assets/img/weixuanzhongyou.png"
                          alt=""
                        />
                        <img
                          class="active_icon"
                          src="../assets/img/youxuanzhong.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="case_card_column case_card_column2">
                    <p class="case_card_column_title">品牌酒店</p>
                    <div class="case_card_column_img_list">
                      <div
                        v-for="(childItem, childIndex) in currentBrandsList"
                        :key="childIndex"
                        class="case_card_column_img"
                        @click.stop.prevent="
                          level2NavClick(
                            item,
                            index,
                            childItem,
                            childIndex,
                            'about'
                          )
                        "
                      >
                        <img
                          class=""
                          :src="$commonJs.formatImgSrc(childItem.logo)"
                          alt=""
                        />
                      </div>
                    </div>
                    <div v-if="allBrandsList" class="case_card_column_bot">
                      <div
                        @click.stop.prevent="getBrandsList(2)"
                        class="case_card_column_bot_column"
                      >
                        <img
                          class="icon"
                          src="../assets/img/weixuanzhongzuo.png"
                          alt=""
                        />
                        <img
                          class="active_icon"
                          src="../assets/img/xuanzhongzuo.png"
                          alt=""
                        />
                      </div>
                      <div
                        @click.stop.prevent="getBrandsList(1)"
                        class="case_card_column_bot_column case_card_column_bot_right"
                      >
                        <img
                          class="icon"
                          src="../assets/img/weixuanzhongyou.png"
                          alt=""
                        />
                        <img
                          class="active_icon"
                          src="../assets/img/youxuanzhong.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 资讯中心二级 :class="
                            childIndex == articleIndex
                              ? 'article_card_content_left_item_active'
                              : ''
                          " -->
                <div
                  v-if="item.id == 5 && info.news"
                  class="nav_card article_card"
                >
                  <div class="article_card_top">
                    <p class="article_card_top_left">热门文章</p>
                    <p class="article_card_top_right">HOT</p>
                  </div>
                  <div class="article_card_line"></div>
                  <div class="article_card_content">
                    <div class="article_card_content_left">
                      <template
                        v-for="(childItem, childIndex) in info.news.list"
                      >
                        <p
                          @click.stop.prevent="
                            level2NavClick(item, index, childItem, childIndex)
                          "
                          :key="childIndex"
                          class="article_card_content_left_item"
                        >
                          {{ childItem.title }}
                        </p>
                      </template>
                    </div>
                    <div class="article_card_content_right">
                      <div class="article_card_content_right_img">
                        <img
                          :src="$commonJs.formatImgSrc(info.news.sel_image)"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 关于我们二级  :class="
                            childIndex == aboutIndex
                              ? 'aboutus_card_content_top_item_active'
                              : ''
                          " -->
                <div
                  v-if="item.id == 6 && info.about"
                  class="nav_card aboutus_card"
                >
                  <div class="aboutus_card_top">
                    <p>关于我们</p>
                  </div>
                  <div class="aboutus_card_line"></div>
                  <div class="aboutus_card_content">
                    <div class="aboutus_card_content_top">
                      <template v-for="(childItem, childIndex) in aboutusList">
                        <div
                          @click.stop.prevent="
                            level2NavClick(item, index, childItem, childIndex)
                          "
                          :key="childIndex"
                          class="aboutus_card_content_top_item"
                        >
                          <span>{{ childItem.title }}</span>
                        </div>
                      </template>
                    </div>
                    <div class="aboutus_card_content_bot">
                      <img
                        :src="$commonJs.formatImgSrc(info.about.image)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar_right">
        <img
          class="navbar_right_tel_img"
          src="@/assets/img/dianhua.gif"
          alt=""
        />
        <span v-if="footInfo.config" class="navbar_right_tel">{{
          footInfo.config.service_mobile
        }}</span>
        <button
          @click="openForm"
          class="navbar_right_btn2"
          style="margin-left: 22px"
        >
          咨询预约
        </button>
        <button @click="toLogin()" class="navbar_right_btn2">企业登录</button>
      </div>
    </div>
    <div v-if="scrollTop > 300" @click="returnTop" class="return_top">
      <img src="../assets/img/fanhuidingbu.png" alt="" />
    </div>
    <FormComponents
      @handlerCancel="handlerCancel"
      :dialogVisible="dialogVisible"
    ></FormComponents>
  </div>
</template>
  
<script>
import { getMenuInfo, getFootInfo } from "@/network/public";
import { BASE_URL } from "@/config/index";

export default {
  name: "SecurityHeader",

  data() {
    return {
      BASE_URL: BASE_URL,
      dialogVisible: false,
      productIndex: -1,
      solutionIndex: -1,
      caseIndex: -1,
      articleIndex: -1,
      aboutUsIndex: -1,
      showLevel2: true,
      navIndex: 1,
      navList: [
        {
          id: 1,
          name: "首页",
          active: false,
          url: "/",
        },
        {
          id: 2,
          name: "产品中心",
          active: false,
          url: "/products",
        },
        {
          id: 3,
          name: "解决方案",
          active: false,
          url: "/solutions",
        },
        {
          id: 4,
          name: "客户案例",
          active: false,
          url: "/customers",
        },
        {
          id: 5,
          name: "资讯中心",
          active: false,
          url: "/news",
        },
        {
          id: 6,
          name: "关于我们",
          url: "/aboutUs",
          active: false,
        },
      ],
      productList: [],
      aboutusList: [
        {
          title: "企业简介",
          id: "intro",
        },
        {
          title: "企业定位",
          id: "product",
        },
        {
          title: "核心价值",
          id: "core",
        },
        {
          title: "荣誉资质",
          id: "certificate",
        },
      ],
      aboutIndex: 0,
      scrollTop: 0,
      info: {},
      footInfo: {},
      allGroupList: [],
      currentGroupList: [],
      groupPage: 1,
      allBrandsList: [],
      currentBrandsList: [],
      brandsPage: 1,
    };
  },

  mounted() {
    this.getInfo();
    this.checkMenuActive();
    window.addEventListener("scroll", this.handleScroll, true); // 监听（绑定）滚轮滚动事件
    this.handleResize(); // 初始化宽度
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      this.checkMenuActive();
    },
  },
  methods: {
    handleResize() {
      // this.screenWidth = window.innerWidth;
      if (window.innerWidth < 950) {
        let queryParams = "";
        if (this.objectToQueryString(this.$route.query)) {
          queryParams = "?" + this.objectToQueryString(this.$route.query);
        }
        let name = this.$route.name;
        let outerUrl = "";
        outerUrl = "/h5";
        switch (name) {
          case "home":
            outerUrl = "/h5";
            break;
          case "products":
            outerUrl = "/h5/pages/products/products" + queryParams;
            break;
          case "solutions":
            outerUrl = "/h5/pages/solutions/solutions" + queryParams;
            break;
          case "form":
            outerUrl = "/h5/pages/form/form";
            break;
          default:
            outerUrl = "/h5";
            break;
        }
        if (outerUrl) {
          window.open(this.BASE_URL + outerUrl, "_self");
        }
      }
    },
    objectToQueryString(obj) {
      // 使用Object.keys获取对象的所有键
      return (
        Object.keys(obj)
          // 使用map遍历每个键，并生成键值对字符串
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
          )
          // 使用join将数组元素连接成字符串，每个元素之间用'&'分隔
          .join("&")
      );
    },
    toLogin() {
      window.open(this.footInfo.config.enterprise_login_url, "_blank");
    },
    handlerCancel() {
      this.dialogVisible = false;
    },
    openForm() {
      this.dialogVisible = true;
    },
    getInfo() {
      getMenuInfo()
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            this.info = res.data;
            // for (let i = 0; i < 11; i++) {
            //   this.info.goods.list.push(this.info.goods.list[0]);
            // }
            this.allGroupList = this.info.case.group;
            this.allBrandsList = this.info.case.brank.list;
            this.currentGroupList = this.paginate(this.groupPage);
            this.currentBrandsList = this.paginate(this.brandsPage, 2);
            this.navList[0].name = res.data.index.title;
            this.navList[1].name = res.data.goods.title;
            this.navList[2].name = res.data.programme.title;
            this.navList[3].name = res.data.case.title;
            this.navList[4].name = res.data.news.title;
            this.navList[5].name = res.data.about.title;
            this.aboutusList[0].title = res.data.about.cates.about_desc;
            this.aboutusList[1].title = res.data.about.cates.about_goods;
            this.aboutusList[2].title = res.data.about.cates.core_values;
            this.aboutusList[3].title = res.data.about.cates.qualifications;
          } else {
            if (res.status == 100005) {
              this.$router.push({
                path: "/empty",
                query: {
                  msg: res.msg,
                },
              });
              return;
            }
          }
        })
        .catch((e) => {});
      getFootInfo()
        .then((res) => {
          if (res.status == 200) {
            this.footInfo = res.data;
            document.title = res.data.config.title;
            document
              .querySelector('meta[name="keywords"]')
              .setAttribute("content", res.data.config.site_keywords);
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", res.data.config.site_desc);
          }
        })
        .catch((e) => {});
    },
    getGroupList(hase) {
      let maxPage = Math.ceil(this.allGroupList.length / 3);
      // console.log(this.allGroupList.length, maxPage);
      if (hase == 1) {
        if (this.groupPage < maxPage) {
          this.groupPage++;
          this.currentGroupList = this.paginate(this.groupPage);
        } else {
          this.$message.info("已经是最后一页");
        }
      } else if (hase == 2) {
        if (this.groupPage > 1) {
          this.groupPage--;
          this.currentGroupList = this.paginate(this.groupPage);
        } else {
          this.$message.info("已经是第一页");
        }
      }
    },
    getBrandsList(hase) {
      let maxPage = Math.ceil(this.allBrandsList.length / 3);
      console.log(this.allBrandsList.length, maxPage);
      if (hase == 1) {
        if (this.brandsPage < maxPage) {
          this.brandsPage++;
          this.currentBrandsList = this.paginate(this.brandsPage, 2);
        } else {
          this.$message.info("已经是最后一页");
        }
      } else if (hase == 2) {
        if (this.brandsPage > 1) {
          this.brandsPage--;
          this.currentBrandsList = this.paginate(this.brandsPage, 2);
        } else {
          this.$message.info("已经是第一页");
        }
      }
    },
    /**
     * 对数组进行分页处理
     * @param {Array} array 需要分页的数组
     * @param {number} pageSize 每页显示的元素数量
     * @param {number} pageNumber 当前页码
     * @returns {Array} 当前页的数据
     */
    paginate(pageNumber, hase) {
      let array = this.allGroupList;
      if (hase == 2) {
        array = this.allBrandsList;
      }
      let pageSize = 3;
      // 确保页码是合理的
      pageNumber = Math.max(1, pageNumber);

      // 计算起始索引
      const startIndex = (pageNumber - 1) * pageSize;

      // 确保起始索引不会超过数组长度
      const endIndex = Math.min(startIndex + pageSize, array.length);

      // 切片返回当前页的数据
      return array.slice(startIndex, endIndex);
    },
    level2NavClick(item, index, childItem, childIndex, hase) {
      this.showLevel2 = false;
      setTimeout(() => {
        this.showLevel2 = true;
      }, 1000);
      switch (index) {
        case 0:
          break;
        case 1:
          this.productIndex = childIndex;
          break;
        case 2:
          this.solutionIndex = childIndex;
          break;
        case 3:
          break;
        case 4:
          this.articleIndex = childIndex;
          break;
        case 5:
          this.aboutIndex = childIndex;
          break;
      }
      if (hase == "about") {
        // 类型 1 文章 2 公众号链接跳转
        if (childItem.type == 2) {
          if (item.url) {
            window.open(childItem.url, "_blank");
          }
        } else if (childItem.type == 1) {
          this.$router.push({
            path: "/caseDetail",
            query: {
              id: childItem.id,
              from: "brand",
            },
          });
        }
      } else {
        if (item.url == "/news") {
          this.$router.push({
            path: "/newsDetail",
            query: {
              id: childItem.id,
            },
          });
        } else {
          this.$router.push({
            path: item.url,
            query: {
              id: childItem.id,
            },
          });
        }
      }
    },
    returnTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    handleScroll(e) {
      if (e.target.scrollingElement) {
        this.scrollTop = e.target.scrollingElement.scrollTop;
      }
      // console.log(e.target.scrollingElement.scrollTop, "滚动距离");
    },
    navClick(item, index) {
      if (item.url) {
        switch (index) {
          case 0:
            this.$router.push(item.url);
            break;
          case 1:
            this.productIndex = 0;
            this.$router.push({
              path: item.url,
              query: {
                id: this.info.goods.list[0].id,
              },
            });
            break;
          case 2:
            this.solutionIndex = 0;
            this.$router.push({
              path: item.url,
              query: {
                id: this.info.programme.list[0].id,
              },
            });
            break;
          case 3:
            this.caseIndex = 0;
            this.$router.push(item.url);
            break;
          case 4:
            this.articleIndex = 0;
            this.$router.push(item.url);
            break;
          case 5:
            this.aboutIndex = 0;
            this.$router.push(item.url);
            break;
          default:
            this.$router.push(item.url);
            break;
        }
        console.log("父元素被点击");
      }
      // this.showLevel2 = false;
    },
    //判断页面头部是不是亮
    checkMenuActive() {
      let name = this.$route.name;
      let routePath = this.$route.path;
      switch (name) {
        case "home":
          this.navIndex = 1;
          break;
        case "products":
          this.navIndex = 2;
          break;
        case "solutions":
          this.navIndex = 3;
          break;
        case "customers":
          this.navIndex = 4;
          break;
        case "caseDetail":
          this.navIndex = 4;
          break;
        case "news":
          this.navIndex = 5;
          break;
        case "newsDetail":
          this.navIndex = 5;
          break;

        case "aboutUs":
          this.navIndex = 6;
          break;
        default:
          break;
      }
      // let curId = this.findParentIdByUrl(this.menuList, routePath);
    },
    findParentIdByUrl(menuList, targetUrl) {
      for (let i = 0; i < menuList.length; i++) {
        const menuItem = menuList[i];
        if (menuItem.url === targetUrl) {
          // 如果 url 匹配，返回当前菜单项的 id
          return menuItem.id;
        }
        if (menuItem.child && menuItem.child.length > 0) {
          // 如果菜单项有子菜单，递归调用 findParentIdByUrl 函数
          const childMenuList = menuItem.child;
          const parentId = this.findParentIdByUrl(childMenuList, targetUrl);
          if (parentId) {
            // 如果子菜单列表中找到了匹配的 id，返回父节点的 id
            return menuItem.id;
          }
        }
      }
      // 如果遍历完菜单列表都没有找到匹配的 id，返回 null
      return null;
    },
  },
};
</script>   
<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  white-space: nowrap;
  height: 66px;
  background: #ffffff;

  .navbar_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    height: 100%;
  }
  .navbar_left {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .navbar_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 61px;
      height: 22px;
      object-fit: contain;
      vertical-align: middle;
    }
  }

  .navbar_content {
    display: flex;
    align-items: center;
    margin-left: 16px;
    height: 100%;
    margin-left: 34px;
    .navbar_content_item:first-child {
      margin-left: 0;
    }
    .navbar_content_item {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 0 18px;
      height: 100%;
      // border-bottom: 3px solid #006c50;
      position: relative;
      cursor: pointer;
      min-width: 90px;
      margin: 0 26px;
      box-sizing: border-box;
      &_icon {
        width: 7.16px;
        height: 3.64px;
        margin-left: 4px;
        transition: all 0.3s ease;
      }

      .navbar_content_item_border {
        width: calc(100% + 20px);
        height: 3px;
        background: #006c50;
        position: absolute;
        bottom: 0;
        // left: -10px;
        width: calc(100%);
      }

      .navbar_content_item_span {
        font-weight: 400;
        font-size: 16px;
        color: rgba(34, 34, 34, 1);
        line-height: 16px;
        text-align: center;
      }

      .nav_card {
        position: absolute;
        left: -10px;
        top: 81px;
        // bottom: -25px;
        background: #ffffff;
        box-shadow: 0px 3px 16px 0px rgba(161, 161, 161, 0.1608);
        border-radius: 16px 16px 16px 16px;
        z-index: 99;
        padding: 35px;
        display: flex;
        justify-content: space-between;
        transition: all 0.3s ease;
        height: 0;
        padding: 0;
        box-sizing: border-box;
        overflow: hidden;
      }

      .product_card {
        // padding: 35px;
        // padding-bottom: 6px;
        // height: auto;
        display: flex;
        align-items: flex-start;
        .product_card_left {
          flex: 1;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 46px;
          // grid-gap: 30px;
          .product_card_left_item {
            padding-right: 90px;
            padding-bottom: 30px;
            &_inner {
              display: flex;
              align-items: center;
            }

            &_icon {
              width: 12px;
              height: 12px;
              object-fit: contain;
            }

            &_span {
              margin-left: 10px;
              font-weight: 400;
              font-size: 16px;
              color: #3b3d3f;
              line-height: 16px;
            }
          }
          .product_card_left_item:nth-child(3n) {
            padding-right: 0;
          }
          .product_card_left_item_active {
            .product_card_left_item_span {
              color: rgba(0, 108, 80, 1);
            }
          }
          .product_card_left_item:hover {
            .product_card_left_item_span {
              color: rgba(0, 108, 80, 1);
            }
          }
        }

        .product_card_right {
          margin-left: 60px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          &_img {
            width: 300px;
            height: 204px;
            border-radius: 12px 12px 12px 12px;
          }

          &_bot {
            margin-top: 23px;
            display: flex;
            align-items: center;
            span {
              font-weight: 400;
              font-size: 14px;
              color: #e7af11;
              line-height: 14px;
            }
            &_icon {
              width: 9px;
              height: 4px;
              margin-left: 6px;
              transform: rotate(270deg);
            }
          }
        }
      }

      /* 滚动条样式优化 */
      ::-webkit-scrollbar {
        width: 0;
        background: #cccccc;
      }

      .solution_card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 94px;
        grid-row-gap: 34px;
        max-height: 40vh;
        overflow-y: scroll;
        // height: auto;
        // padding: 35px;

        &_item {
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
          &_icon {
            width: 15px;
            height: 15px;
          }
          &_span {
            margin-left: 10px;
            font-weight: 400;
            font-size: 16px;
            color: #3b3d3f;
            line-height: 16px;
            flex: 1;
          }
          &_logo {
            width: 113px;
            height: 36.99px;
            margin-left: 48px;
            img {
              border-radius: 8px 8px 8px 8px;
              width: 100%;
              height: 100%;
              object-fit: contain;
              box-shadow: 0px 3px 16px 0px rgba(161, 161, 161, 0.1608);
            }
          }
        }
        .solution_card_item:hover {
          .solution_card_item_span {
            color: rgba(0, 108, 80, 1);
          }
        }
        .solution_card_item_active {
          .solution_card_item_span {
            color: rgba(0, 108, 80, 1);
          }
        }
      }
      .case_card {
        display: flex;
        align-items: flex-start;
        .case_card_column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &_title {
            font-weight: 400;
            font-size: 16px;
            color: #3b3d3f;
            line-height: 16px;
          }

          &_img_list {
            margin-top: 8px;
          }

          &_img {
            margin-top: 5px;
            width: 113px;
            height: 37px;
            background: #ffffff;
            box-shadow: 0px 3px 16px 0px rgba(161, 161, 161, 0.1608);
            border-radius: 8px 8px 8px 8px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 8px 8px 8px 8px;
            }
          }

          &_bot {
            display: flex;
            align-items: center;
            margin-top: 15px;
            img {
              width: 18px;
              height: 18px;
            }
            .case_card_column_bot_right {
              margin-left: 15px;
            }
            .case_card_column_bot_column {
              .active_icon {
                display: none;
              }
              .icon {
                display: block;
              }
            }
            .case_card_column_bot_column:hover {
              .active_icon {
                display: block;
              }
              .icon {
                display: none;
              }
            }
          }
        }
        .case_card_column2 {
          margin-left: 41px;
        }
      }

      .article_card {
        display: flex;
        flex-direction: column;
        align-items: center;
        .article_card_top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &_left {
            font-weight: 400;
            font-size: 16px;
            color: #3b3d3f;
            line-height: 16px;
          }
          &_right {
            // width: 42px;
            // height: 20px;
            background: #ff4a3b;
            border-radius: 10px 10px 10px 10px;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            line-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 7px;
          }
        }
        .article_card_line {
          width: 100%;
          height: 1px;
          background: #c3c3c3;
          margin-top: 6px;
          margin-bottom: 19px;
        }

        .article_card_content {
          display: flex;
          justify-content: space-between;

          &_left {
            display: flex;
            flex-direction: column;

            &_item {
              font-weight: 290;
              font-size: 14px;
              line-height: 19px;
              margin-bottom: 14px;
              padding-left: 14px;
              border-left: 5px solid transparent;
            }

            .article_card_content_left_item:hover {
              color: #006c50;
              border-left: 5px solid #006c50;
            }
            &_item_active {
              color: #006c50;
              border-left: 5px solid #006c50;
            }
          }

          &_right {
            margin-left: 60px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            &_img {
              width: 170px;
              height: 170px;
              border-radius: 16px;
              cursor: auto;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 16px;
              }
            }
            &_bot {
              margin-top: 23px;
              display: flex;
              align-items: center;
              span {
                font-weight: 400;
                font-size: 14px;
                color: #e7af11;
                line-height: 14px;
              }
              &_icon {
                width: 9px;
                height: 4px;
                margin-left: 6px;
                transform: rotate(270deg);
              }
            }
          }
        }
      }
      .aboutus_card {
        display: flex;
        flex-direction: column;
        .aboutus_card_top {
          p {
            font-weight: 400;
            font-size: 16px;
            color: #3b3d3f;
            line-height: 16px;
          }
        }
        .aboutus_card_line {
          width: 100%;
          height: 1px;
          background: rgba(195, 195, 195, 1);
          margin-top: 10px;
          margin-bottom: 27px;
        }
        .aboutus_card_content {
          .aboutus_card_content_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .aboutus_card_content_top_item {
              padding: 2px 12px;
              border-radius: 10px 10px 10px 10px;
              span {
                font-weight: 290;
                font-size: 14px;
                color: #6c6c6c;
                line-height: 19px;
                text-align: left;
              }
            }
            .aboutus_card_content_top_item:hover {
              background: rgba(0, 108, 80, 0.1);
              span {
                color: rgba(0, 108, 80, 1);
              }
            }
            .aboutus_card_content_top_item_active {
              background: rgba(0, 108, 80, 0.1);
              span {
                color: rgba(0, 108, 80, 1);
              }
            }
          }
          .aboutus_card_content_bot {
            margin-top: 27px;
            width: 378px;
            height: 226px;
            border-radius: 10px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    .navbar_content_item:hover {
      .navbar_content_item_icon {
        transform: rotate(180deg);
      }
      .navbar_content_item_span {
        color: rgba(0, 108, 80, 1);
      }
      .nav_card {
        // opacity: 1;
        padding: 35px;
        height: auto;
        // padding-bottom: 21px;
      }
      .product_card {
        padding-bottom: 6px;
      }
    }

    .navbar_content_item_active {
      .navbar_content_item_span {
        color: rgba(0, 108, 80, 1);
      }
    }
  }

  .navbar_right {
    display: flex;
    align-items: center;
    &_tel_img {
      width: 22px;
      height: 22px;
      border-radius: 50%;
    }

    &_tel {
      margin-left: 7px;
      font-weight: 300;
      font-size: 14px;
      color: #222222;
      line-height: 14px;
    }

    &_btn2 {
      font-weight: bold;
      font-size: 14px;
      color: #222222;
      line-height: 14px;
      padding: 9px 16px;
      border-radius: 30px;
      background: #ffffff;
      cursor: pointer;
      margin-left: 12px;
      border: 1.2px solid rgba(0, 108, 80, 1);
      color: rgba(0, 108, 80, 1);
    }
    .navbar_right_btn2:hover {
      background: #006c50;
      color: #ffffff;
    }
  }
}

.return_top {
  position: fixed;
  bottom: 20vh;
  right: 52px;
  cursor: pointer;
  img {
    width: 56px;
    height: 56px;
  }
}
</style>   